import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { BalanceData, TransactionData } from './PaymentTypes';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, SnackbarCloseReason } from '@mui/material';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: any },
    b: { [key in Key]: any },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof TransactionData;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [

    {
        id: 'userName',
        numeric: true,
        disablePadding: false,
        label: 'user',
    },
    {
        id: 'amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'paymentMode',
        numeric: true,
        disablePadding: false,
        label: 'Mode',
    },
    {
        id: 'hashRateInTh',
        numeric: true,
        disablePadding: false,
        label: 'hash (TH)',
    },
    {
        id: 'created',
        numeric: true,
        disablePadding: false,
        label: 'created',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TransactionData) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof TransactionData) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
interface EnhancedTableToolbarProps {
    selectedItems: string[];
    refreshData: () => void;
}
function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { selectedItems, refreshData } = props;
    const [approved, setApproved] = useState<boolean>(false);
    const [rejected, setRejected] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState<String | null>(null)
    const [alertType, setAlertType] = useState<"error" | "success">("success")

    function handleDialogClose() {
        if (loading) return;
        setApproved(false)
        setRejected(false)
    }
    function handleSnackbarClose() {
        setAlertMsg(null)
    }
    function doApprove() {
        processData("APPROVED")
    }
    function doReject() {
        processData("REJECTED")
    }
    function processData(status: string) {
        if (selectedItems.length == 0) {
            setAlertMsg("No items selected")
            setAlertType("error");
            return;
        }
        setLoading(true);
        axios.post('/api/segPoolRewardDistribution/update', { ids: selectedItems, status: status }, getAxiosConfig())
            .then(x => {
                setAlertMsg("Updated")
                setAlertType("success");
                setLoading(false);
                handleDialogClose();
                refreshData();
            })
            .catch(e => {
                setLoading(false);
                setAlertMsg("Error updating info");
                setAlertType("error");
            })
    }
    return (
        selectedItems.length > 0 ? 
        <Toolbar
            sx={[
                {
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                },
                selectedItems.length > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                },
            ]}
        >
            {selectedItems.length > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {selectedItems.length} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}
            {selectedItems.length > 0 ? (
                <>
                    <Tooltip title="Reject">
                        <IconButton aria-label="Reject" color="error" sx={{ m: 1 }} onClick={() => { setRejected(true) }}>
                            <BlockIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Approve">
                        <IconButton aria-label="Approve" color="success" sx={{ m: 1 }} onClick={() => { setApproved(true) }}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : (
                <></>
            )}
            <Dialog
                open={approved}
                onClose={handleDialogClose}
                aria-labelledby="Approve transactions" >
                <DialogContent>
                    <DialogContentText >
                        Approve all selected transactions
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}> Cancel </Button>
                    <Button onClick={doApprove} autoFocus disabled={loading}> Approve </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={rejected}
                onClose={handleDialogClose}
                aria-labelledby="Reject transactions" >
                <DialogContent>
                    <DialogContentText >
                        Reject all selected transactions
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}> Cancel </Button>
                    <Button onClick={doReject} autoFocus disabled={loading}> Reject </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={alertMsg != null} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={alertType}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Toolbar>
        : <></>
    );
}

interface TableComponentProps {
    rows: TransactionData[];
    refreshData: () => void;
    //balance: BalanceData | null;
}

const PaymentTable: React.FC<TableComponentProps> = ({ rows, refreshData }) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof TransactionData>('id');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [selectedAmounts, setSelectedAmounts] = useState<readonly string[]>([]);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackText, setSnackText] = React.useState('');


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof TransactionData,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string, amount: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);

        const selectedAmountIndex = selectedAmounts.indexOf(amount);
        let newSelectedAmounts: readonly string[] = [];

        if (selectedAmountIndex === -1) {
            newSelectedAmounts = newSelectedAmounts.concat(selectedAmounts, amount);
        } else if (selectedAmountIndex === 0) {
            newSelectedAmounts = newSelectedAmounts.concat(selectedAmounts.slice(1));
        } else if (selectedAmountIndex === selectedAmounts.length - 1) {
            newSelectedAmounts = newSelectedAmounts.concat(selectedAmounts.slice(0, -1));
        } else if (selectedAmountIndex > 0) {
            newSelectedAmounts = newSelectedAmounts.concat(
                selectedAmounts.slice(0, selectedAmountIndex),
                selectedAmounts.slice(selectedAmountIndex + 1),
            );
        }

        setSelectedAmounts(newSelectedAmounts);
        const totalAmount = newSelectedAmounts.reduce((acc, current) => {
            return acc + parseFloat(current);
        }, 0);

        // if (balance?.available && (balance.available - totalAmount) < 0) {
        //     setSnackText(`Insufficient Balance ${balance?.available - totalAmount}`);
        //     setSnackOpen(true)
        // }
        // else { setSnackOpen(false) }
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            [...rows]
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage],
    );

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <Paper sx={{ width: '100%' ,padding:'16px'}}>
            <Box>
                <EnhancedTableToolbar selectedItems={[...selected]} refreshData={refreshData} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = selected.includes(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id ,row.amount)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="right"
                                        >
                                            {row.userName}
                                        </TableCell>
                                        <TableCell align="right">{row.amount}</TableCell>
                                        <TableCell align="right">{row.paymentMode}</TableCell>
                                        <TableCell align="right">{row.hashRateInTh}</TableCell>
                                        <TableCell align="right">{row.created}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    paddingX : '16px'
                }}>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                />
                <TablePagination
                    rowsPerPageOptions={[25, 50, 75, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Box>
            </Box>
            
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    variant="filled"
                    sx={{ width: '100%' }}
                    severity="error"
                >
                    {snackText}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default PaymentTable;