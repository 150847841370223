import React, { useState, useEffect } from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Modal,
    TextField,
    MenuItem,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Snackbar,
    Alert,
} from '@mui/material';
import { Edit, SettingsInputComponent, Update } from '@mui/icons-material';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';

interface NicehashData {
    id: string;
    region: string;
    minBufferCount: number;
    algorithm: string;
    maxPrice: number;
    offset: number;
    isAlive: boolean;
    speedLabel: string;
    orderType: string;
}

function Nicehash() {
    const [tableModalOpen, setTableModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [tableData, setTableData] = useState<NicehashData[]>([]);
    const [formData, setFormData] = useState<NicehashData>({
        id: '',
        region: '',
        minBufferCount: 0,
        algorithm: '',
        maxPrice: 0,
        offset: 0,
        isAlive: true,
        speedLabel: '',
        orderType: '',
    });
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const fetchData = () => {
        axios
            .get(API_PATH + '/nicehash/price/config', getAxiosConfig())
            .then((response) => {
                setTableData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleTableModalOpen = () => {
        setTableModalOpen(true);
    };

    const handleTableModalClose = () => {
        setTableModalOpen(false);
    };

    const handleEditModalOpen = (data: NicehashData) => {
        setFormData(data);
        setEditModalOpen(true);
    };

    const handleEditModalClose = () => {
        setEditModalOpen(false);
    };

    const handleChange = (field: keyof NicehashData) => (event: React.ChangeEvent<HTMLInputElement>) => {

        console.log(event.target.value, typeof (event.target.value));
        
        setFormData({ ...formData, [field]: event.target.value });
    };

    const handleSave = () => {
        debugger;
        setLoading(true);
        axios
            .post(API_PATH + '/nicehash/price/config', formData, getAxiosConfig())
            .then(() => {
                setSnackbar({ open: true, message: 'Data saved successfully!', severity: 'success' });
                fetchData();
                handleEditModalClose();
            })
            .catch((error) => {
                console.error('Error saving data:', error);
                setSnackbar({ open: true, message: 'Failed to save data.', severity: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box p={2}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ cursor: 'pointer' }} onClick={handleTableModalOpen}>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between" color="#003878">
                                <Typography variant="h6" mb={0} fontWeight="bold">
                                    PRICE CONFIGURATION
                                </Typography>
                                <SettingsInputComponent />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Modal open={tableModalOpen} onClose={handleTableModalClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Nicehash Price Configuration
                    </Typography>
                    <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 400 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Region</TableCell>
                                    <TableCell>Algorithm</TableCell>
                                    <TableCell>Offset</TableCell>
                                    <TableCell>Min Buffer Count</TableCell>
                                    <TableCell>Max Price</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.region}</TableCell>
                                        <TableCell>{row.algorithm}</TableCell>
                                        <TableCell>{row.offset}</TableCell>
                                        <TableCell>{row.minBufferCount}</TableCell>
                                        <TableCell>{row.maxPrice}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleEditModalOpen(row)}>
                                                <Edit/>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button variant="contained" onClick={handleTableModalClose}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal open={editModalOpen} onClose={handleEditModalClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Edit Price Configuration
                    </Typography>
                    <TextField
                        fullWidth
                        select
                        label="Region"
                        value={formData.region}
                        onChange={handleChange('region')}
                        margin="normal"
                    >
                        <MenuItem value="EU">EU</MenuItem>
                        <MenuItem value="USA">USA</MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        label="Minimum Buffer Count"
                        value={formData.minBufferCount}
                        onChange={handleChange('minBufferCount')}
                        margin="normal"
                        type="number"
                    />
                    <TextField
                        fullWidth
                        select
                        label="Algorithm"
                        value={formData.algorithm}
                        onChange={handleChange('algorithm')}
                        margin="normal"
                    >
                        <MenuItem value="SHA256ASICBOOST">SHA256ASICBOOST</MenuItem>
                        <MenuItem value="KHEAVYHASH">KHEAVYHASH</MenuItem>
                    </TextField>
                    <TextField
                        fullWidth
                        label="Maximum Price"
                        value={formData.maxPrice}
                        onChange={handleChange('maxPrice')}
                        margin="normal"
                        type="number"
                    />
                    <TextField
                        fullWidth
                        label="Offset"
                        value={formData.offset}
                        onChange={handleChange('offset')}
                        margin="normal"
                        type="number"
                    />
                    <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
                        <Button variant="outlined" onClick={handleEditModalClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSave}
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20} /> : null}
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity as any}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default Nicehash;
