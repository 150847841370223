import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import AddCustomerModal from './AddCustomerModal';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ConfirmationModal from './ConfirmationModal';
import { API_PATH } from '../../../App';
import TableLoader from '../GlobalComponents/TableLoader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryIcon from '@mui/icons-material/History';
import HistoryModal from '../GlobalComponents/HistoryModal';
import AddButton from '../GlobalComponents/AddButton';
import { Customer } from './types';



export default function CustomerList() {
    const [rows, setRows] = useState<Customer[]>([]);
    const [countryList, setCountryList] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchCustomerList();
        fetchCountryList();
    }, []);

    const fetchCountryList = () => {
        axios.get(API_PATH + '/customer/countries', getAxiosConfig())
            .then(response => {
                setCountryList(response.data);
            })
            .catch(error => {
                console.error("Error fetching country list:", error);
            });
    };

    const fetchCustomerList = () => {
        axios.get(API_PATH + '/customer/list?orderBy=none', getAxiosConfig())
            .then(response => {
                setRows(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching customer list:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedCustomer(null);
    };

    const handleSubmit = (data: { id: number | null; customerGroupName: string; country: string; isActive: boolean }) => {
        setLoading(true);
        axios.post(API_PATH + '/customer/save', data, getAxiosConfig())
            .then(response => {
                fetchCustomerList();
                setLoading(false);
            });
    };

    const handleEdit = (customer: Customer) => {
        setSelectedCustomer(customer);
        handleOpenModal();
        handleMenuClose();
    };

    const handleOpenConfirmationModal = (customer: Customer) => {
        setSelectedCustomer(customer);
        setConfirmationModalOpen(true);
        handleMenuClose();
    };

    const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

    const handleDisableCustomer = () => {
        if (selectedCustomer) {
            const updatedCustomer = {
                ...selectedCustomer,
                isActive: selectedCustomer.isActive ? false : true,
            };
            handleSubmit(updatedCustomer);

            setConfirmationModalOpen(false);
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, customer: Customer) => {
        setAnchorEl(event.currentTarget);
        setSelectedCustomer(customer);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleHistoryOpen = () => {
        setHistoryModalOpen(true);
        handleMenuClose();
    };

    const handleHistoryClose = () => setHistoryModalOpen(false);

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
                {loading ? (
                    <TableLoader />
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <Table sx={{ minWidth: 650 }} aria-label="customer table">
                        <TableHead>
                                <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                <TableCell sx={{ width: '40%' }}>Customer Name</TableCell>
                                <TableCell sx={{ width: '30%' }}>Mobile</TableCell>
                                <TableCell sx={{ width: '25%' }}>Country</TableCell>
                                <TableCell sx={{ width: '5%', textAlign: 'right' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.customerGroupName}
                                    </TableCell>
                                    <TableCell>{row.number}</TableCell>
                                    <TableCell>{row.country}</TableCell>
                                    <TableCell sx={{ width: '5%', textAlign: 'right' }}>
                                        <IconButton
                                            onClick={(event) => handleMenuOpen(event, row)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && selectedCustomer === row}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => handleEdit(row)}>
                                                <EditIcon sx={{ mr: 1 }} /> Edit
                                            </MenuItem>
                                            <MenuItem onClick={() => handleOpenConfirmationModal(row)}>
                                                {row.isActive ? (
                                                    <>
                                                        <BlockIcon sx={{ mr: 1 }} /> Disable
                                                    </>
                                                ) : (
                                                    <>
                                                        <CheckCircleOutlineIcon sx={{ mr: 1 }} /> Enable
                                                    </>
                                                )}
                                            </MenuItem>
                                            <MenuItem onClick={handleHistoryOpen}>
                                                <HistoryIcon sx={{ mr: 1 }} /> History
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Paper>
            <AddButton onClick={handleOpenModal} />
            <AddCustomerModal
                open={modalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmit}
                countryList={countryList}
                selectedData={selectedCustomer}
            />
            <ConfirmationModal
                open={confirmationModalOpen}
                handleClose={handleCloseConfirmationModal}
                handleConfirm={handleDisableCustomer}
                customerName={selectedCustomer?.customerGroupName || ''}
                action={selectedCustomer?.isActive ? "Disable" : "Enable"}
            />
            <HistoryModal
                open={historyModalOpen}
                onClose={handleHistoryClose}
                selectedId={selectedCustomer?.id || null}
                entity={'Customer'}
            />
        </Box>
    );
}
