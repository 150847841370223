import axios from "axios";
import Cookies from "js-cookie";

export function getAxiosConfig() {
    return { headers: { Authorization: `Bearer ${Cookies.get('authinfo')}` } }
}

export function setupAxios(token: string | undefined) {
    if (token != undefined && token.length > 0) {
        axios.defaults.headers.common = { 'Authorization': `bearer ${token}` }
    }
}

export function authLogin(username: string, password: string, success: (token: string) => void, fail: () => void) {
    axios.post('/auth/login/', { "username": username, "password": password })
        .then((response) => {
            if (response.data.status) {
                Cookies.set('authinfo', response.data.token)
                success(response.data.token)
            } else {
                Cookies.set('authinfo', "")
                console.log(response.data)
            }
        })
        .catch((error) => {
            console.log(error);
            fail()
        });
}

export function signOut() {
    Cookies.set('authinfo', "")
}