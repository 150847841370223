import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const HashLoader = () => {
    return (
        <Box sx={{ height: 'auto' ,width:'100%',padding:'16px'}} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ height: '50%' }}>
                        <Grid item xs={6}>
                            <Skeleton variant="rectangular" width="100%" height={80} />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant="rectangular" width="100%" height={80} />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant="rectangular" width="100%" height={80} />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant="rectangular" width="100%" height={80} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HashLoader;
