import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TablePagination, Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';

interface AuditLogChange {
    id: number;
    fieldChanged: string;
    previousValues: string;
    newValues: string;
}

interface HistoryEntry {
    id: number;
    entityName: string;
    entityId: number;
    action: string;
    modifiedBy: string;
    modifiedDate: string;
    auditLogChanges: AuditLogChange[];
}

interface HistoryModalProps {
    open: boolean;
    onClose: () => void;
    selectedId: number | null;
    entity: string;
}

const HistoryModal: React.FC<HistoryModalProps> = ({ open, onClose, selectedId, entity }) => {
    const [historyData, setHistoryData] = useState<HistoryEntry[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        if (selectedId) {
            fetchCustomerHistory(selectedId, page, rowsPerPage, entity);
        }
    }, [selectedId, page, rowsPerPage]);

    const fetchCustomerHistory = (customerId: number, page: number, limit: number, entity: string) => {        
        axios.get(`${API_PATH}/audit/check?entity=${entity}&id=${customerId}&page=${page}&limit=${limit}`, getAxiosConfig())
            .then((response) => {

                setHistoryData(response.data.content);
                setTotalRecords(response.data.totalElements);
            })
            .catch((error) => {
                console.error('Error fetching customer history', error);
            });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogContent>
                {historyData.length === 0 ? (
                    <Typography>No history available for this customer.</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ padding: '6px' }}><strong>Field Changed</strong></TableCell>
                                    <TableCell sx={{ padding: '6px' }}><strong>Previous Value</strong></TableCell>
                                    <TableCell sx={{ padding: '6px' }}><strong>New Value</strong></TableCell>
                                </TableRow>
                                {historyData.map((history, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <TableCell colSpan={3} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item>
                                                        <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#ddd' : '#000' }}>
                                                            <strong>Action: </strong> {history.action}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#ddd' : '#000' }}>
                                                            <strong>Modified By: </strong> {history.modifiedBy} ({new Date(history.modifiedDate).toLocaleString()})
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                        {history.auditLogChanges.map((change, changeIndex) => (
                                            <TableRow key={changeIndex}>
                                                <TableCell sx={{ padding: '6px' }}>{change.fieldChanged}</TableCell>
                                                <TableCell sx={{ padding: '6px' }}>{change.previousValues.split("\n").map(x => <>{x}<br /></>)}</TableCell>
                                                <TableCell sx={{ padding: '6px' }}>{change.newValues.split("\n").map(x => <>{x}<br /></>)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>
                )}
            </DialogContent><DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Button onClick={onClose} variant="contained" sx={{ ml: 4 }}>
                        Close
                    </Button>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        component="div"
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default HistoryModal;
