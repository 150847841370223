import { DataEntry } from "./types";

export function calculateTotalInitialCash(data: any): number {
    return (
        Number(data.machinePrice) +
        data.hostingRevenueAdvance * (data.hostingRate * (data.machinePower / 1000) * 24)
    );
}

export function calculateProductionInCoin(
    day: number,
    expenseRevenue: number,
    productionInCoin: number,
    difficultyIncrease: number
): number {
    return day === 0
        ? expenseRevenue
        : productionInCoin - expenseRevenue * difficultyIncrease;
}


export function calculateElectricityExpense(data: any): number {
    return (data.machinePower / 1000) * data.hostingRate * 24;
}

export function createDataEntry(
    currentDate: Date,
    productionInCoin: number,
    productionInUSD: number,
    electricityExpense: number,
    minerProfit: number,
    coinBalance: number,
    currentCoinPrice: number,

): DataEntry {
    return {
        date: currentDate.toLocaleDateString(),
        productionInCoin,
        productionInUSD,
        electricityExpense,
        minerProfit,
        coinBalance,
        currentCoinPrice
    };
}



export function formatDateToDDMMYYYY(dateInput: Date | string): string {
    const date = typeof dateInput === "string" ? new Date(dateInput) : dateInput;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}