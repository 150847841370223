import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { MachineConfigRowState } from './ConfigModal';

interface OnOffButtonGroupProps {
    row: MachineConfigRowState;
    index: number;
    handleButtonGroupChange: (index: number, newState: "ON" | "OFF" | "NEW") => void;
}

const MachineOnOffButtonGroup: React.FC<OnOffButtonGroupProps> = ({ row, index, handleButtonGroupChange }) => {
    return (
        <ButtonGroup variant="outlined" aria-label="On/Off button group">
            <Button
                onClick={() => handleButtonGroupChange(index, 'ON')}
                variant={row.onOff === 'ON' ? 'contained' : 'outlined'}
            >
                On
            </Button>
            <Button
                onClick={() => handleButtonGroupChange(index, 'OFF')}
                variant={row.onOff === 'OFF' ? 'contained' : 'outlined'}
            >
                Off
            </Button>
            <Button
                onClick={() => handleButtonGroupChange(index, 'NEW')}
                variant={row.onOff === 'NEW' ? 'contained' : 'outlined'}
                disabled={row.onOff === 'ON' || row.onOff === 'OFF'}
            >
                New
            </Button>
        </ButtonGroup>
    );
};

export default MachineOnOffButtonGroup;
