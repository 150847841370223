import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import SeachIcon from '@mui/icons-material/Search';
import InvoiceModal from './InvoiceModal';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { Invoice } from './types';
import TableLoader from '../GlobalComponents/TableLoader';
import { Button, Menu, MenuItem } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import HistoryModal from '../GlobalComponents/HistoryModal';
import AddButton from '../GlobalComponents/AddButton';

const InvoiceList = () => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [selectedInvoiceMenu, setSelectedInvoiceMenu] = useState<Invoice | null>(null);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<number | ''>('');
    const [invoicesList, setInvoicesList] = useState<Invoice[]>([]);
    const [totalItemCount, setTotalItemCount] = useState<number>(0);
    const [pageableData, setPageableData] = useState<{ pageNumber: number; pageSize: number } | null>(null);
    const [loading, setLoading] = useState<boolean>(true)
    const [trigerList, setTriggerList] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchInvoiceList();
    }, [page, rowsPerPage, trigerList]);

    const handleRowClick = (invoice: Invoice) => {
        setSelectedInvoice(invoice);
        setIsAdding(false);
        setSelectedCustomer(invoice.id);
    };

    const fetchInvoiceList = async () => {
        setLoading(true);
        axios.get(`${API_PATH}/invoice/list?key=${searchQuery}&page=${page}&limit=${rowsPerPage}`, getAxiosConfig())
            .then(response => {
                setInvoicesList(response.data.content);
                setTotalItemCount(response.data.totalElements);
                setPageableData({
                    pageNumber: response.data.pageable.pageNumber,
                    pageSize: response.data.pageable.pageSize,
                });
            })
            .catch(error => {
                console.error("Error fetching invoice list:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const handleSearchButtonClick = () => {
        fetchInvoiceList()
    }

    const handleAddClick = () => {
        setSelectedInvoice(null);
        setIsAdding(true);
    };

    const handleCloseModal = () => {
        setSelectedInvoice(null);
        setIsAdding(false);
        setTriggerList(!trigerList);
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, invoice: Invoice) => {
        setAnchorEl(event.currentTarget);
        setSelectedInvoiceMenu(invoice);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedInvoice(null);
    };

    const handleHistoryOpen = () => {
        setHistoryModalOpen(true);
    };

    const handleHistoryClose = () => {
        setHistoryModalOpen(false);
    };

    function formatDateToDDMMYYYY(isoString: string): string {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    return (
        <Box sx={{ flexGrow: 1, p: 2, position: 'relative', height: 'calc(100vh - 64px)' }}>
            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ width: '300px', mr: 2 }}
                    />
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearchButtonClick}
                    startIcon={<SeachIcon />}
                    sx={{ height: '100%' }}
                >
                    Search
                </Button>
            </Box>
            <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
                {loading ? (
                    <TableLoader />
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                <TableCell>Id</TableCell>
                                <TableCell>Customer</TableCell>
                                <TableCell>Reference</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Total Price</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoicesList.map((invoice) => (
                                <TableRow key={invoice.id}>
                                    <TableCell>{invoice.id}</TableCell>
                                    <TableCell>{invoice.customer?.customerGroupName}</TableCell>
                                    <TableCell>{invoice.reference}</TableCell>
                                    <TableCell>{formatDateToDDMMYYYY(invoice.invoiceDate)}</TableCell>
                                    <TableCell>{invoice.totalPrice}</TableCell>
                                    <TableCell>{invoice.status}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={(event) => handleMenuClick(event, invoice)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && selectedInvoiceMenu === invoice}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => handleRowClick(invoice)}>
                                                <EditIcon sx={{ mr: 1 }} /> Edit
                                            </MenuItem>
                                            <MenuItem onClick={handleHistoryOpen}>
                                                <HistoryIcon sx={{ mr: 1 }} /> History
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalItemCount}
                    rowsPerPage={pageableData?.pageSize || 10}
                    page={pageableData?.pageNumber || 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <AddButton onClick={handleAddClick} />
            <InvoiceModal
                open={Boolean(selectedInvoice || isAdding)}
                isAdding={isAdding}
                onClose={handleCloseModal}
                selectedInvoice={selectedInvoice}
                setSelectedInvoice={setSelectedInvoice}
                setTriggerList={setTriggerList}
                trigerList={trigerList}
            />
            <HistoryModal
                open={historyModalOpen}
                onClose={handleHistoryClose}
                selectedId={selectedInvoiceMenu?.id || null}
                entity={'Invoice'}
            />
        </Box>
    );
};

export default InvoiceList;
