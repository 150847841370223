import { DataEntry, SimulatorCardData } from "../pages/Components/Simulator/types";
import { calculateElectricityExpense, calculateProductionInCoin, calculateTotalInitialCash, createDataEntry } from "../pages/Components/Simulator/utils";

export const generateDateRange = (data: SimulatorCardData, updateTreasuryCutOffDay: (day: number) => void): { datas: DataEntry[], setTreasuryCutOffDay: number } => {
    let setTreasuryCutOffDay = 0;
    const startDate = new Date();
    const coinPriceIncrease = data.coinPriceIncrease / 100;
    const difficultyIncrease = data.difficultyIncreaseMonthly / 100;
    const expenseRevenue = Number(data.expenseRevenue) * (Number(data.uptimeGuarantee) / 100);
    const totalInitialCash = calculateTotalInitialCash(data);
    const totalCoinPurchase = totalInitialCash / data.coinCurrentPrice;
    const datas: DataEntry[] = [];
    
    let currentDate = new Date(startDate);
    let coinBalance = totalCoinPurchase;
    let currentCoinPrice = data.coinCurrentPrice;
    let productionInCoin = 0;
    let productionInUSD = 0;
    let minerProfit = 1;
    let isFirstMonth = true;

    const maxDays = 1825;

    for (let day = 0; minerProfit > 0 && day < maxDays; day++) {
        
        //updateTreasuryCutOffDay(day)

        if (currentDate.getDate() === 1) {
            currentCoinPrice *= 1 + coinPriceIncrease;
        }

        if (day === 0 || currentDate.getDate() === 1) {
            productionInCoin = calculateProductionInCoin(day, expenseRevenue, productionInCoin, difficultyIncrease);
            productionInUSD = productionInCoin * currentCoinPrice;
        }

        if (Number(data.hostingBillCutoffDay) === currentDate.getDate()) {
            setTreasuryCutOffDay = day;

            const dailyHostingCharge = (data.hostingRate * (data.machinePower / 1000) * 24) / currentCoinPrice;

            if (isFirstMonth) {
                const date = new Date();
                if (Number(data.hostingBillCutoffDay) > date.getDate()) {
                    const daysToCutoff = Number(data.hostingBillCutoffDay) - date.getDate();
                    const hostingCharge = (daysToCutoff) * dailyHostingCharge;
                    coinBalance += hostingCharge;
                } else if (Number(data.hostingBillCutoffDay) < date.getDate()) {
                    const daysToNextCutoff = 30 - date.getDate();
                    const hostingCharge = daysToNextCutoff * dailyHostingCharge;
                    coinBalance += hostingCharge;
                } else {
                    const hostingCharge = 0;
                    coinBalance += hostingCharge;
                }
                isFirstMonth = false;
            } else {
                const hostingCharge = dailyHostingCharge * 30;
                coinBalance += hostingCharge;
            }
        }

        const electricityExpense = calculateElectricityExpense(data);
        minerProfit = productionInUSD - electricityExpense;

        coinBalance -= productionInCoin;

        datas.push(
            createDataEntry(
                currentDate,
                productionInCoin,
                productionInUSD,
                electricityExpense,
                minerProfit,
                coinBalance,
                currentCoinPrice
            )
        );
        currentDate.setDate(currentDate.getDate() + 1);   
    }

    return { datas, setTreasuryCutOffDay };
};
