import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import AddMachineModal from './AddMachineModal';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { API_PATH } from '../../../App';
import TableLoader from '../GlobalComponents/TableLoader';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryModal from '../GlobalComponents/HistoryModal';
import AddButton from '../GlobalComponents/AddButton';
import { Machine } from './types';



export default function MachineList() {
    const [rows, setRows] = useState<Machine[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchMachineList();
    }, []);

    const fetchMachineList = () => {
        axios.get(API_PATH + '/machine_model/list', getAxiosConfig())
            .then(response => {
                setRows(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching machine list:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, machine: Machine) => {
        setAnchorEl(event.currentTarget);
        setSelectedMachine(machine);
    };

    const handleSubmit = (save: Machine) => {
        const formattedData = {
            id: save.id,
            modelName: save.modelName,
            coin: save.coin.coin,
            hashRate: save.hashRate,
            hashUnit: save.hashUnit,
            vendor: save.vendor.id,
            isActive: save.isActive,
            price: save.price,
            power: save.power,
        };

        setLoading(true);
        axios.post(API_PATH + '/machine_model/save', formattedData, getAxiosConfig())
            .then(response => {
                fetchMachineList();
                setLoading(false);
                handleCloseModal();
            })
            .catch(error => {
                console.error("Error saving machine data:", error);
                setError("Failed to save data.");
                setLoading(false);
            });
    };

    const handleOpenModal = () => setModalOpen(true);

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedMachine(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = (machine: Machine) => {
        setSelectedMachine(machine);
        setModalOpen(true);
        handleMenuClose();
    };

    const handleHistoryOpen = () => {
        setHistoryModalOpen(true);
        handleMenuClose();
    };

    const handleHistoryClose = () => setHistoryModalOpen(false);

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
                {loading ? (
                    <TableLoader />
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <Table sx={{ minWidth: 650 }} aria-label="machine table">
                        <TableHead>
                                <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                <TableCell>Model Name</TableCell>
                                <TableCell>Vendor</TableCell>
                                <TableCell>Coin</TableCell>
                                <TableCell>Algorithm</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Power (KW)</TableCell>
                                <TableCell>Hash Rate</TableCell>
                                <TableCell>Hash Unit</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">{row.modelName}</TableCell>
                                    <TableCell>{row.vendor.vendorName}</TableCell>
                                    <TableCell>{row.coin.coin}</TableCell>
                                    <TableCell>{row.coin.algorithm}</TableCell>
                                    <TableCell>{row.price}</TableCell>
                                    <TableCell>{row.power}</TableCell>
                                    <TableCell>{row.hashRate}</TableCell>
                                    <TableCell>{row.hashUnit}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={(event) => handleMenuClick(event, row)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && selectedMachine === row}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => handleEdit(row)}>
                                                <EditIcon sx={{ mr: 1 }} /> Edit
                                            </MenuItem>
                                            <MenuItem onClick={handleHistoryOpen}>
                                                <HistoryIcon sx={{ mr: 1 }} /> History
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Paper>
            <AddButton onClick={handleOpenModal} />
            <AddMachineModal
                open={modalOpen}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmit}
                selectedData={selectedMachine}
            />
            <HistoryModal
                open={historyModalOpen}
                onClose={handleHistoryClose}
                selectedId={selectedMachine?.id || null}
                entity={'MachineModel'}
            />
        </Box>
    );
}
