import React, { useEffect, useState } from 'react';
import {
    TextField,
    Box,
    Button,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import axios from 'axios';
import TableLoader from '../GlobalComponents/TableLoader';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { convertHashRate } from '../../utils/HashrateUtils';

interface EmitterHash {
    worker: string;
    hash15Min: number;
    hash24Hr: number;
    status: 'ONLINE' | 'OFFLINE';
    coin: string | null;
    workerList: string[];
    hashrate: number;
    pool: any;
}

interface TransformedData {
    emitter: string;
    worker: string;
    hashrate: number;
    workerList: string[];
    workerLength: number;
}

interface AccumulatedData {
    worker: string;
    emitters: string;
    hashrate: number;
}

function WorkerList() {
    const [rows, setRows] = useState<TransformedData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<string[]>([]);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<'hashrate' | 'emitters' | 'worker'>('hashrate');
    const [searchOptions, setSearchOptions] = useState<string[]>([]);

    useEffect(() => {
        fetchEmitterHashList();
    }, []);

    const fetchEmitterHashList = async () => {
        setLoading(true);
        try {
            const response = await axios.get(API_PATH + '/monitor/emitterhash', getAxiosConfig());
            const data: EmitterHash[] = response.data;

            const transformedData = data.flatMap(item =>
                item.workerList ? item.workerList.map(emitter => ({
                    worker: emitter,
                    emitter: item.worker,
                    workerLength: item.workerList.length,
                })) : []
            );

            const options = Array.from(
                new Set(
                    transformedData.map(item => item.worker.split('.')[0].toLowerCase())
                )
            ).map(worker =>
                transformedData.find(item => item.worker.split('.')[0].toLowerCase() === worker)?.worker.split('.')[0]
            ).filter((worker): worker is string => worker !== undefined);
            setSearchOptions(options);

            const emitterResponse = await axios.get(API_PATH + '/nicehash/emitters', getAxiosConfig());
            const emitterData = emitterResponse.data;

            const mergedData = transformedData.map((emitterHash:any) => {
                const matchingEmitter = emitterData.find((emitter: any) => emitter.pool.username === emitterHash.emitter);

                if (matchingEmitter) {
                    return {
                        ...emitterHash,
                        hashrate: convertHashRate(matchingEmitter.limit, matchingEmitter.pool.algorithm) / emitterHash.workerLength || 0
                    };
                } else {
                    return {
                        ...emitterHash,
                        hashrate: 0
                    };
                }
            });

            setRows(mergedData);
        } catch (error) {
            console.error('Error fetching emitter data:', error);
            setError('Failed to fetch data.');
        } finally {
            setLoading(false);
        }
    };

    const filteredRows = rows.filter(row => {
        if (searchTerm.length === 0) return true;
        return searchTerm.some(term => row.worker.trim().toLowerCase().includes(term.toLowerCase()));
    });

    const reducedData = Object.values(
        filteredRows.reduce<{ [key: string]: AccumulatedData }>(
            (acc, current) => {
                const key = current.worker;
                if (!acc[key]) {
                    acc[key] = {
                        worker: current.worker,
                        emitters: current.emitter,
                        // count: 1,
                        hashrate: current.hashrate
                    };
                } else {
                    const emitterRegex = new RegExp(`(${current.emitter})( \\((\\d+)\\))?`);
                    const match = acc[key].emitters.match(emitterRegex);

                    if (match) {
                        const currentCount = match[3] ? parseInt(match[3], 10) : 1;
                        const newCount = currentCount + 1;
                        acc[key].emitters = acc[key].emitters.replace(emitterRegex, `${current.emitter}${newCount > 1 ? ` (${newCount})` : ''}`);
                    } else {
                        acc[key].emitters += ` #${current.emitter}`;
                    }
                    // acc[key].count++;
                    acc[key].hashrate += current.hashrate;
                }
                return acc;
            },
            {}
        )
    );


    const handleRequestSort = (property: 'hashrate' | 'emitters' | 'worker') => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getComparator = (order: 'asc' | 'desc', orderBy: 'hashrate' | 'emitters' | 'worker') => {
        return (a: AccumulatedData, b: AccumulatedData) => {
            if (orderBy === 'hashrate') {
                if (b.hashrate < a.hashrate) return order === 'desc' ? -1 : 1;
                if (b.hashrate > a.hashrate) return order === 'desc' ? 1 : -1;
            } else if (orderBy === 'emitters') {
                const emittersA = a.emitters.toLowerCase();
                const emittersB = b.emitters.toLowerCase();
                if (emittersB < emittersA) return order === 'desc' ? -1 : 1;
                if (emittersB > emittersA) return order === 'desc' ? 1 : -1;
            } else if (orderBy === 'worker') {
                const workerA = a.worker.toLowerCase();
                const workerB = b.worker.toLowerCase();
                if (workerB < workerA) return order === 'desc' ? -1 : 1;
                if (workerB > workerA) return order === 'desc' ? 1 : -1;
            }
            return 0;
        };
    };

    const stableSort = (array: AccumulatedData[], comparator: (a: AccumulatedData, b: AccumulatedData) => number) => {
        const stabilizedThis = array.map((el, index) => [el, index] as [AccumulatedData, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const downloadCSV = (data: any[]) => {
        const headers = ['Worker', 'Emitters', 'Hashrate'];
        const rows = data.map(row => [
            row.worker,
            row.emitters,
            parseFloat(row.hashrate.toFixed(2)),
        ]);

        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(',')),
        ].join('\n');

        const now = new Date();
        const padZero = (num: number) => num.toString().padStart(2, '0');
        const day = padZero(now.getDate());
        const month = padZero(now.getMonth() + 1);
        const fileName = `WorkerData_${day}-${month}.csv`;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button variant="contained" sx={{ mb: 2, mr: 2 }} size='small' onClick={() => downloadCSV(stableSort(reducedData, getComparator(order, orderBy)))}>
                    <Download />
                </Button>
                <Autocomplete
                    multiple
                    options={searchOptions}
                    value={searchTerm}
                    sx={{ width: '40%', mb: 2 }}
                    onChange={(event, newValue) => setSearchTerm(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"

                            label="Search"
                        />
                    )}
                />
            </Box>
            {loading ? (
                <TableLoader />
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                <TableCell align="left">
                                    <TableSortLabel active={orderBy === 'worker'} direction={order} onClick={() => handleRequestSort('worker')}>
                                        Worker
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'emitters'} direction={order} onClick={() => handleRequestSort('emitters')}>
                                        Emitters
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel active={orderBy === 'hashrate'} direction={order} onClick={() => handleRequestSort('hashrate')}>
                                        Hashrate
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(reducedData, getComparator(order, orderBy)).map((row) => (
                                <TableRow key={row.worker} >
                                    <TableCell align="left" >{row.worker}</TableCell>
                                    <TableCell align="center" >{row.emitters}</TableCell>
                                    <TableCell align="right" >{row.hashrate.toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

export default WorkerList;
