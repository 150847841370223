import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Collapse,
    Box,
    Paper,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import axios, { AxiosResponse } from "axios";
import { API_PATH } from "../../../App";
import { getAxiosConfig } from "../../../helpers/AuthHelper";

interface ApiCallInfo {
    name: string;
    results: (callback: (x: string) => void) => void;
}

const rows: ApiCallInfo[] = [
    {
        name: "/api/healthCheckApi/emitterMismatch",
        results: (cb) => {
            axios.get(API_PATH + '/healthCheckApi/emitterMismatch', getAxiosConfig())
                .then(r => { cb(formatJson(r.data)) })
        }
    },
    {
        name: "/api/healthCheckApi/niceHashWalletInfo",
        results: (cb) => {
            axios.get(API_PATH + '/healthCheckApi/niceHashWalletInfo', getAxiosConfig())
                .then(r => { cb(formatJson(r.data)) })
        }
    },
    {
        name: "/api/healthCheckApi/niceHashApiStatus",
        results: (cb) => {
            axios.get(API_PATH + '/healthCheckApi/niceHashApiStatus', getAxiosConfig())
                .then(r => { cb(formatJson(r.data)) })
        }
    },
];

function formatJson(o: any): string {
    return JSON.stringify(o, null, 4)
}

const ExpandableTableRow: React.FC<{ row: ApiCallInfo }> = ({ row }) => {
    const [open, setOpen] = useState(false);
    const [loadedResult, setLoadedResult] = useState<null | String>(null);
    useEffect(() => {
        if (!open) {
            setLoadedResult(null)
            return
        }
        row.results(setLoadedResult);
    }, [open]);
    return (
        <>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <strong>Details:</strong> <br />
                            {loadedResult != null ? <pre style={{ backgroundColor: "#f4f4f4", fontSize: "12px", padding: "10px", borderRadius: "5px" }}> {loadedResult}</pre> : <span>Loading...</span>}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};


const ApiExpandableTable: React.FC = () => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Api</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <ExpandableTableRow key={index} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>)
}



export default ApiExpandableTable;