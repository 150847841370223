import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const BalanceLoader = () => {
    return (
        <Box sx={{ height: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ height: '50%' }}>
                        <Grid item xs={4}>
                            <Skeleton variant="rectangular" width="100%" height={70} />
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton variant="rectangular" width="100%" height={70} />
                        </Grid>
                        <Grid item xs={4}>
                            <Skeleton variant="rectangular" width="100%" height={70} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BalanceLoader;
