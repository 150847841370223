import { Box, Button, Grid, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import Emitter from './Emitter';
import Daterange from './Daterange';
import { Search } from '@mui/icons-material';
import WorkerData from './WorkerData';

interface Props { }

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ReportControl(props: Props) {
    const [value, setValue] = React.useState(0);
    const [fromDate, setFromDate] = useState<Date | null>(new Date());
    const [toDate, setToDate] = useState<Date | null>(new Date());
    const [searchTrigger, setSearchTrigger] = useState(false)

    const handleChange = (newValue: number) => {
        setValue(newValue);
    };


    const { } = props

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%',
                    borderBottom: 1,
                    borderColor: 'transparent',
                    marginTop: 2,
                    paddingX:4,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '20%',
                        marginRight:5,
                    }}
                >
                    <Button
                        variant={value === 0 ? 'contained' : 'outlined'}
                        onClick={() => handleChange(0)}
                        sx={{
                            flex: '1 0 10%',
                            margin: '0 4px',
                        }}
                    >
                        Worker
                    </Button>
                    <Button
                        variant={value === 1 ? 'contained' : 'outlined'}
                        onClick={() => handleChange(1)}
                        sx={{
                            flex: '1 0 10%',
                            margin: '0 4px',
                        }}
                    >
                        Emitter
                    </Button>

                </Box>
                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', gap: '8px', }}>
                    <Daterange
                        fromDate={fromDate}
                        toDate={toDate}
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        setSearchTrigger={setSearchTrigger}
                        searchTrigger={searchTrigger}

                    />
                    <Grid item sm="auto">
                        <Button variant="contained" size="small" sx={{ paddingY: 1 }} onClick={() => { setSearchTrigger(!searchTrigger) }}>
                            <Search />
                        </Button>
                    </Grid>
                </Box>
            </Box>

            <Box >
                <CustomTabPanel value={value} index={0}>
                    <WorkerData fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} searchTrigger={searchTrigger} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Emitter fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} searchTrigger={searchTrigger} />
                </CustomTabPanel>
            </Box>
        </Box>

    )
}

export default ReportControl
