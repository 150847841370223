import React, { useState } from 'react';
import {Dialog} from '@mui/material';
import { DataEntry, SimulatorCardData } from './types';
import ExecutionResultTable from './ExpandedExecutionTable';
import { generateDateRange } from '../../../functions/generateDateRange';

interface ExecutionModalProps {
    open: boolean;
    onClose: () => void;
    data: SimulatorCardData;
}

const ExecutionModal: React.FC<ExecutionModalProps> = ({ open, onClose, data }) => {
    const [treasuryCutOffDay, setTreasuryCutOffDay] = useState<number>(0);
    


    const { datas } = generateDateRange(data, setTreasuryCutOffDay);


    const handleDownloadCSV = () => {
        const csvContent =
            'Date,Production in Coin (BTC),Production in USD,Electricity Expense ($),Miner Profit ($),Coin Balance in Treasury (BTC)\n' +
            datas.map((row) =>
                    [
                        row.date,
                        row.productionInCoin.toFixed(8),
                        row.productionInUSD.toFixed(4),
                        row.electricityExpense.toFixed(4),
                        row.minerProfit.toFixed(4),
                        row.coinBalance.toFixed(8),
                    ].join(',')
                )
                .join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'execution_results.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <ExecutionResultTable
                resultTableData={datas}
                setTreasuryCutOffDay={treasuryCutOffDay}
                handleDownloadCSV={handleDownloadCSV}
                onClose={onClose}
            />
        </Dialog>
    );
};

export default ExecutionModal;




