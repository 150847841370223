import React from 'react';
import {Dialog,DialogTitle,DialogContent,Table,TableBody,TableCell,TableContainer,TableRow,Paper,Typography,TableHead,Box, Fab,} from '@mui/material';
import { Close } from '@mui/icons-material';

interface SimulationResult {
    simulatorId: number;
    breakEvenDays: string | number;
    breakEvenDate: string;
    treasuryBalanceOnExit: number;
    treasuryBalanceInUSD: number;
    minCoinBalanceDate: any;
    minCoinBalance: number;
    minCoinBalanceDay: string | number;
    minCoinBalanceInUSD: number
    currentCoinPrice:number;
}

interface SimulationResultsModalProps {
    open: boolean;
    onClose: () => void;
    results: SimulationResult[];
}

const SimulationResultsModal: React.FC<SimulationResultsModalProps> = ({ open, onClose, results }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
                    <DialogTitle style={{ padding: '0px' }}>Simulation Results</DialogTitle>
                    <Fab
                        size="small"
                        onClick={onClose}
                        color="primary"
                        aria-label="close"
                        sx={{ mr: 2, mt: 2 }}
                    >
                        <Close />
                    </Fab>
                </Box>
                {results.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#d1e8ff' }}>
                                    <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '4px', textAlign: 'center' }}>Simulation Name</TableCell>
                                    <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '4px', textAlign: 'center' }}>Total Days</TableCell>
                                    <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '4px', textAlign: 'center' }}>Date on Break Even</TableCell>
                                    <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '4px', textAlign: 'center' }}>Treasury Balance on Exit</TableCell>
                                    <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '4px', textAlign: 'center' }}>Treasury Balance in USD</TableCell>
                                    <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '4px', textAlign: 'center' }}>Current Coin Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {results.map((result) => (
                                    <TableRow key={result.simulatorId}>
                                        <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff', fontSize: 'small', textAlign: 'center' }}>
                                            Simulator {result.simulatorId}
                                        </TableCell>
                                        {result.minCoinBalanceDay === 0 ? (
                                            <>
                                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{result.breakEvenDays}</TableCell>
                                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{result.breakEvenDate}</TableCell>
                                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{result.treasuryBalanceOnExit.toFixed(8)}</TableCell>
                                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{result.treasuryBalanceInUSD}</TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>
                                                    {result.minCoinBalanceDay < result.breakEvenDays ? result.minCoinBalanceDay : result.breakEvenDays}
                                                </TableCell>
                                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>
                                                    {result.minCoinBalanceDay < result.breakEvenDays ? result.minCoinBalanceDate : result.breakEvenDate}
                                                </TableCell>
                                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>
                                                        {result.minCoinBalanceDay < result.breakEvenDays ? result.minCoinBalance.toFixed(8) : result.treasuryBalanceOnExit.toFixed(8)}
                                                </TableCell>
                                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>
                                                        {result.minCoinBalanceDay < result.breakEvenDays ? result.minCoinBalanceInUSD.toFixed(4) : result.treasuryBalanceInUSD.toFixed(4)}
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>
                                            {result.currentCoinPrice.toFixed(4)}
                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>No Results Found</Typography>
                )}

            </DialogContent>
        </Dialog>
    );
};

export default SimulationResultsModal;
