import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range-ts';
import 'react-date-range-ts/dist/styles.css';
import 'react-date-range-ts/dist/theme/default.css';
import { Button, Modal, Box, Typography } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';

interface DaterangeProps {
    fromDate: Date | null;
    toDate: Date | null;
    setFromDate: (date: Date | null) => void;
    setToDate: (date: Date | null) => void;
    setSearchTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    searchTrigger: boolean;
}

const Daterange: React.FC<DaterangeProps> = ({ fromDate, toDate, setFromDate, setToDate, setSearchTrigger, searchTrigger, }) => {
    const [selectionRange, setSelectionRange] = useState({
        startDate: fromDate || new Date(),
        endDate: toDate || new Date(),
        key: 'selection',
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clickCount, setClickCount] = useState(0);

    useEffect(() => {
        setSelectionRange({
            startDate: fromDate || new Date(),
            endDate: toDate || new Date(),
            key: 'selection',
        });
    }, [fromDate, toDate]);

    useEffect(() => {
        if (clickCount === 2) {
            toggleModal()
        }
    }, [clickCount]);

    const handleSelect = (ranges: any) => {
        const { startDate, endDate } = ranges.selection;
        setSelectionRange({
            startDate,
            endDate,
            key: 'selection',
        });
        setFromDate(startDate);
        setToDate(endDate);
        if (fromDate && toDate) {
            setClickCount(prev => prev + 1);
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        setClickCount(0);
    };

    const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div>
            <Button variant="outlined" size='large' onClick={toggleModal}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarIcon sx={{ marginRight: 1 }} />
                    <strong>{formatDate(selectionRange.startDate)} - {formatDate(selectionRange.endDate)}</strong>
                </Box>
            </Button>


            <Modal
                open={isModalOpen}
                onClose={toggleModal}
                aria-labelledby="date-range-picker"
                aria-describedby="modal-to-select-date-range"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="date-range-picker" variant="h6" component="h2">
                        Select Date Range
                    </Typography>
                    <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        direction="horizontal"
                        retainEndDateOnFirstSelection={true}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default Daterange;
