import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

const TableLoader = () => {
    return (
        <Box sx={{ height: 'auto' }}>
            <Grid container spacing={2}>
                {[...Array(10)].map((_, index) => (
                    <Grid item xs={12} key={index}>
                        <Grid container spacing={2} sx={{ height: '50%' }}>
                            <Grid item xs={3}>
                                <Skeleton variant="rectangular" width="100%" height={40} />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton variant="rectangular" width="100%" height={40} />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton variant="rectangular" width="100%" height={40} />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton variant="rectangular" width="100%" height={40} />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TableLoader;
