import React, { useState } from 'react';
import { Typography,Box, Fab } from '@mui/material';
import { SimulatorCard } from './SimulatorCard';
import SimulationResultsModal from './SimulationResultsModal';
import { AddCircle, PlayCircle } from '@mui/icons-material';
import { ExtendedDateEntry, SimulatorCardData } from './types';
import { calculateElectricityExpense, calculateProductionInCoin, calculateTotalInitialCash, formatDateToDDMMYYYY } from './utils';

const initialCardData = (id: number, copyData: SimulatorCardData | null): SimulatorCardData => {

    if (copyData) {
        return { ...copyData, id };
    }
    return {
        id,
        contractInput: '',
        machineHashRate: 0,
        machinePower: 0,
        machinePrice: 0,
        hostingRate: 0.06,
        hostingBillCutoffDay: '0',
        uptimeGuarantee: '100',
        expenseRevenue: '',
        expenseRevenueDoller: '',
        difficultyIncreaseMonthly: 2,
        coinPriceIncrease: 2,
        coinCurrentPrice: 0,
        hostingRevenueAdvance: 0,
        currentDifficulty: 0,
        currentCoinPrice: 0,
    };
};



const generateDateRange = (data: SimulatorCardData) => {
    const startDate = new Date();
    const coinPriceIncrease = data.coinPriceIncrease / 100;
    const difficultyIncrease = data.difficultyIncreaseMonthly / 100;
    const expenseRevenue = Number(data.expenseRevenue) * (Number(data.uptimeGuarantee) / 100);

    const totalInitialCash = calculateTotalInitialCash(data);
    const totalCoinPurchase = totalInitialCash / data.coinCurrentPrice;

    let treasuryLoseData = {
        days: 0,
        cutOffDate: new Date(),
        treasuryBalance: 0,
        treasuryBalanceInUSD: 0,
        currentCoinPrice: 0,
    } as {
        days: number;
        cutOffDate: Date;
        treasuryBalance: number;
        treasuryBalanceInUSD: number;
        currentCoinPrice: number;
    };


    const datas: ExtendedDateEntry[] = [];

    let currentDate = startDate;
    let coinBalance = totalCoinPurchase;
    let currentCoinPrice = data.coinCurrentPrice;
    let productionInCoin = 0;
    let productionInUSD = 0;
    let minerProfit = 1;
    let isFirstMonth = true;

    let minCoinBalance = 0;
    let minCoinBalanceDate;
    let minCoinBalanceDay = 0;
    let minCoinCurrentprice = 0;

    let prevCoinBalance = 0

    let day = 0;
    const maxDays = 1825;

    while (minerProfit > 0 && day < maxDays) {

        prevCoinBalance = currentCoinPrice

        if (currentDate.getDate() === 1) {
           // currentDifficulty *= 1 + difficultyIncrease;
            currentCoinPrice *= 1 + coinPriceIncrease;
        }

        if (day === 0 || currentDate.getDate() === 1) {
            productionInCoin = calculateProductionInCoin(day, expenseRevenue, productionInCoin, difficultyIncrease);
            productionInUSD = productionInCoin * currentCoinPrice;
        }

        if (Number(data.hostingBillCutoffDay) === currentDate.getDate()) {

            treasuryLoseData = {
                days: day,
                cutOffDate: currentDate,
                treasuryBalance: coinBalance,
                treasuryBalanceInUSD: coinBalance * currentCoinPrice,
                currentCoinPrice: currentCoinPrice,
            };

            const dailyHostingCharge =
                (data.hostingRate * (data.machinePower / 1000) * 24) / currentCoinPrice;

            if (isFirstMonth) {
                const date = new Date();


                if (Number(data.hostingBillCutoffDay) > date.getDate()) {
                    const daysToCutoff = Number(data.hostingBillCutoffDay) - date.getDate();
                    const hostingCharge = (daysToCutoff) * dailyHostingCharge;
                    coinBalance += hostingCharge;
                } else if (Number(data.hostingBillCutoffDay) < date.getDate()) {
                    const daysToNextCutoff = 30 - date.getDate();
                    const hostingCharge = daysToNextCutoff * dailyHostingCharge;
                    coinBalance += hostingCharge;
                }
                else {
                    const hostingCharge = 0;
                    coinBalance += hostingCharge;
                }
                isFirstMonth = false;
            } else {
                const hostingCharge = dailyHostingCharge * 30;
                coinBalance += hostingCharge;
            }
        }

        const electricityExpense = calculateElectricityExpense(data);
        minerProfit = productionInUSD - electricityExpense;

        coinBalance -= productionInCoin;

        if (minCoinBalance > coinBalance) {
            minCoinBalance = coinBalance;
            minCoinBalanceDate = formatDateToDDMMYYYY(currentDate.toDateString());
            minCoinBalanceDay = day;
            minCoinCurrentprice = currentCoinPrice
        }

        datas.push({
            date: currentDate.toLocaleDateString(),
            productionInCoin,
            productionInUSD,
            electricityExpense,
            minerProfit,
            coinBalance,
            minCoinBalance,
            minCoinBalanceDate,
            minCoinBalanceDay,
        });

        currentDate.setDate(currentDate.getDate() + 1);

        day++;
    }

    return {
        datas,
        breakEvenDays: day >= maxDays ? 'N/A' : (treasuryLoseData.days ? treasuryLoseData.days : day),
        treasuryBalance: (treasuryLoseData.treasuryBalance ? treasuryLoseData.treasuryBalance : coinBalance),
        treasuryBalanceInUSD: (treasuryLoseData.treasuryBalance ? treasuryLoseData.treasuryBalance : coinBalance) * prevCoinBalance,
        minCoinBalance: minCoinBalance,
        minCoinBalanceDate: minCoinBalanceDate,
        minCoinBalanceDay: minCoinBalanceDay,
        minCoinBalanceInUSD: minCoinBalance * minCoinCurrentprice,
        currentCoinPrice: minCoinCurrentprice ? minCoinCurrentprice : prevCoinBalance
    };
};

const SimulatorControll: React.FC = () => {
    const [simulators, setSimulators] = useState<SimulatorCardData[]>([
        initialCardData(1, null)
    ]);
    const [simulationResults, setSimulationResults] = useState<any[]>([]);
    const [isModalOpen, setModalOpen] = useState(false);

    const handleAddSimulator = () => {
        const newSimulator = initialCardData(
            simulators.length + 1,
            simulators.length > 0 ? simulators[simulators.length - 1] : null
        );
        setSimulators([...simulators, newSimulator]);
    };

    const handleUpdateSimulator = (updatedData: SimulatorCardData) => {
        setSimulators((prevSimulators) =>
            prevSimulators.map((sim) => (sim.id === updatedData.id ? updatedData : sim))
        );
    };

    const handleRemoveSimulator = (id: number) => {
        setSimulators((prevSimulators) => prevSimulators.filter((sim) => sim.id !== id));
    };

    const executeSimulation = () => {
        const results = simulators.map((simulator) => {
            const { breakEvenDays, treasuryBalance, treasuryBalanceInUSD, minCoinBalance, minCoinBalanceDate, minCoinBalanceDay, minCoinBalanceInUSD, currentCoinPrice } =
                generateDateRange(simulator);

            return {
                simulatorId: simulator.id,
                breakEvenDays,
                breakEvenDate:
                    breakEvenDays !== 'N/A'
                        ? new Date(Date.now() + (Number(breakEvenDays) - 1) * 24 * 60 * 60 * 1000).toLocaleDateString()
                        : 'N/A',
                treasuryBalanceOnExit: treasuryBalance,
                treasuryBalanceInUSD: treasuryBalanceInUSD,
                minCoinBalance: minCoinBalance,
                minCoinBalanceDate: minCoinBalanceDate !== undefined
                    ? minCoinBalanceDate
                    : 'N/A',
                minCoinBalanceDay: minCoinBalanceDay,
                minCoinBalanceInUSD: minCoinBalanceInUSD,
                currentCoinPrice: currentCoinPrice
            };
        });

        setSimulationResults(results);
        setModalOpen(true);
    };

    return (
        <div style={{ padding: '16px' }}>
            <Box
                display="flex"
                alignItems="center"
                position="sticky"
                top="70px"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                gap={1}
                zIndex={100}
                sx={{
                    mb: 2,
                    backgroundColor: '#d1e8ff',
                    padding: { xs: 1, sm: 1.5 },
                    borderRadius: 1,
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: 'monospace',
                        ml: { xs: 0, sm: '1rem' },
                        color: '#1565c0',
                        textAlign: { xs: 'center', sm: 'left' },
                        width: '100%',
                    }}
                    gutterBottom
                >
                    SIMULATOR
                </Typography>
                <Box
                    display="flex"
                    gap={1}
                    width="100%"
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                >
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={handleAddSimulator}
                        color="primary"
                        aria-label="add"
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                            typography: { xs: 'caption', sm: 'button' },
                        }}
                    >
                        <AddCircle sx={{ mr: 1 }} />
                        <Typography
                            sx={{
                                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                            }}
                        >
                            Add New
                        </Typography>
                    </Fab>
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={executeSimulation}
                        color="primary"
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                            typography: { xs: 'caption', sm: 'button' },
                        }}
                    >
                        <PlayCircle sx={{ mr: 1 }} />
                        <Typography
                            sx={{
                                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                            }}
                        >
                            Execute
                        </Typography>
                    </Fab>

                </Box>
            </Box>

            {simulators.map((simulator) => (
                <SimulatorCard
                    key={simulator.id}
                    data={simulator}
                    onUpdate={handleUpdateSimulator}
                    onClose={handleRemoveSimulator}
                />
            ))}


            <SimulationResultsModal
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                results={simulationResults}
            />
        </div>
    );
};


export default SimulatorControll;
