import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Card, CardContent, CardActionArea } from '@mui/material';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { API_PATH } from '../../../App';
import HashLoader from '../GlobalComponents/HashLoader';

interface Detail {
    id: string;
    heading: string;
    description: string;
}

export default function MonitorHome() {
    const [details, setDetails] = useState<Detail[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDetails();
    }, []);

    const fetchDetails = () => {
        axios.get(API_PATH + '/monitor/details', getAxiosConfig())
            .then(response => {
                setDetails(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching details:", error);
                setError("Failed to fetch data.");
                setLoading(false);
            });
    };

    const handleCardClick = (id: string) => {
        navigate(`/monitor/${id}`);
    };

    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid container spacing={2}>
                {loading ? (
                    <HashLoader />
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <>
                        {details.map((detail) => (
                            <Grid item xs={12} sm={6} md={6} key={detail.id}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <CardActionArea onClick={() => handleCardClick(detail.id)}>
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography variant="h5" component="div">
                                                {detail.heading}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {detail.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                        <Grid item xs={12} sm={6} md={6} key="worker">
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardActionArea onClick={() => handleCardClick('worker')}>
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography variant="h5" component="div">
                                            Worker
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Worker vs Emitter
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}
