import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, Badge, TablePagination, Checkbox, Grid } from '@mui/material';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import NotificationLoader from './NotificationLoader';

interface NotificationModalProps {
    open: boolean;
    onClose: () => void;
    notifications: Notification[];
    pagination: PaginationState;
    loading: boolean;
}

interface Notification {
    id: string;
    message: string;
    infos: string[];
}

interface PaginationState {
    page: number;
    rowsPerPage: number;
    totalItemCount: number;
    pageableData: { pageNumber: number; pageSize: number } | null;
}

export const CustomNotifications: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(0);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        page: 0,
        rowsPerPage: 10,
        totalItemCount: 0,
        pageableData: null,
    });
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    var timer: any = null;

    useEffect(() => {
        checkForNotification();
        timer = setTimeout(() => {
            checkForNotification();
        }, 60000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (open) {
            fetchNotificationData();
        }
    }, [open, page, rowsPerPage]);

    function checkForNotification() {
        axios.get(API_PATH + '/alerts/check', getAxiosConfig()).then((response) => {
            setCount(response.data);
        });
    }

    function fetchNotificationData() {
        setLoading(true);
        axios
            .get(
                `${API_PATH}/alerts/list?page=${page}&limit=${rowsPerPage}&read=${notifications.map((notification) => notification.id).join(',')}`,
                getAxiosConfig()
            )
            .then((res) => {
                setNotifications(res.data?.content || []);
                setPagination((prevState) => ({
                    ...prevState,
                    totalItemCount: res.data.totalElements,
                    pageableData: {
                        pageNumber: res.data.pageable.pageNumber,
                        pageSize: res.data.pageable.pageSize,
                    },
                }));
                checkForNotification()
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleNotification = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPagination((prevState) => ({ ...prevState, page: newPage }));
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleMarkAsRead = () => {
        fetchNotificationData();
    };

    const NotificationModal: React.FC<NotificationModalProps> = ({
        open,
        onClose,
        notifications,
        pagination,
        loading,
    }) => (
        <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ width: '70%', minHeight: '70vh', maxHeight: '70vh', bgcolor: 'background.paper', p: 4, position: 'relative', overflowY: 'auto' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Notifications
                </Typography>
                {loading && <NotificationLoader />}
                {!loading && (
                    <div>
                        {notifications.map((item) => (
                            <Grid container sx={{ width: '100%' }}>
                                <Grid item xs={12}>
                                    <Accordion sx={{ my: 1 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography>{item.message}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {item.infos.map((infos: string) => (
                                                <Typography>
                                                    {infos}
                                                </Typography>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 'auto' }}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={pagination.totalItemCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 'auto' }}>
                    <Button onClick={onClose} variant="contained" sx={{ m: 2 }}>
                        Close
                    </Button>
                    <Button onClick={handleMarkAsRead} variant="contained" sx={{ m: 2 }}>
                        Mark As Read
                    </Button>
                </Box>
            </Box>
        </Modal>
    );

    return (
        <>
            {(count > 0) && (
                <IconButton
                    aria-label="add"
                    onClick={handleNotification}
                    sx={{
                        position: 'fixed',
                        bottom: 40,
                        left: 40,
                        width: 40,
                        height: 40,
                        color: '#ff0000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2000,
                        '&:hover': {
                            color: '#ff0009',
                        },
                    }}
                >
                    <Badge badgeContent={count} color="primary">
                        <MarkUnreadChatAltIcon fontSize="large" />
                    </Badge>
                </IconButton>
            )}
            <NotificationModal
                open={open}
                onClose={handleClose}
                notifications={notifications}
                pagination={pagination}
                loading={loading}
            />
        </>
    );
};