import * as React from 'react';
import { useParams } from 'react-router-dom';
import EmitterHashList from './EmitterHashList';
import HashList from './HashList';
import { Box, Typography } from '@mui/material';
import WorkerList from './WorkerList';

export default function DynamicComponentRenderer() {
    const { id } = useParams<{ id: string }>();

    return (
        <Box sx={{ p: 2 }}>
            {id === 'emitter' ? (
                <EmitterHashList />
            ) : id === 'hash' ? (
                <HashList />
            ) : id === 'worker' ? (
                <WorkerList />
            ) : (
                <Typography variant="h6" color="error">
                    Component not found for ID: {id}
                </Typography>
            )}
        </Box>

    );
}
