
const HashRateUnit = {
    H: 1,
    KH: 1000,
    MH: 1000000,
    GH: 1000000000,
    TH: 1000000000000,
    PH: 1000000000000000,
    EH: 1000000000000000000,
} as const;

type Algorithm = 'SHA256ASICBOOST' | 'KHEAVYHASH';

// Define the conversion function with types for rate and algorithm
/**
 * Converts hash rate based on the algorithm and outputs the result in TH (terahashes).
 * 
 * @param {number} rate - The hash rate to convert.
 * @param {Algorithm} algorithm - The algorithm to determine the unit to convert from.
 * @returns {number} - The converted rate in TH (terahashes).
 */
export function convertHashRate(rate: number, algorithm: Algorithm): number {
    const algorithmToUnit: Record<Algorithm, keyof typeof HashRateUnit> = {
        SHA256ASICBOOST: 'EH',
        KHEAVYHASH: 'PH',
    };

    const fromUnit = algorithmToUnit[algorithm];
    const toUnit = 'TH'; 

    if (fromUnit === toUnit) {
        return rate;
    }

    return rate * (HashRateUnit[fromUnit] / HashRateUnit[toUnit]);
}
