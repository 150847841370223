import { Box, Typography } from "@mui/material";
import ApiExpandableTable from "./ApiExpandableTable";



const ApiList: React.FC = () => {
    return (
        <Box sx={{ flexGrow: 1, p: 2, position: 'relative', height: 'calc(100vh - 64px)' }}>
            <Box
                display="flex"
                alignItems="center"
                position="sticky"
                top="70px"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                gap={1}
                zIndex={100}
                sx={{
                    mb: 2,
                    backgroundColor: '#d1e8ff',
                    padding: { xs: 1, sm: 1.5 },
                    borderRadius: 1,
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: 'monospace',
                        ml: { xs: 0, sm: '1rem' },
                        color: '#1565c0',
                        textAlign: { xs: 'center', sm: 'left' },
                        width: '100%',
                    }}
                    gutterBottom
                >
                    API Calls
                </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
                <ApiExpandableTable />

            </Box>
        </Box >)
}



export default ApiList;