import React from 'react';
import { Grid, Select, MenuItem, TextField, Button, Autocomplete, SelectChangeEvent } from '@mui/material';
import { Search, Download } from '@mui/icons-material';

interface SearchFiltersProps {
    selectedPool: string;
    handlePoolChange: (event: SelectChangeEvent<string>) => void;
    searchOptions: SearchOption[];
    searchTerm: number[];
    setSearchTerm: (value: number[]) => void;
    searchMachineOptions: SearchOption[];
    searchMachineTerm: number[];
    setSearchMachineTerm: (value: number[]) => void;
    searchGroupTerm: string[];
    setSearchGroupTerm: (value: string[]) => void;
    searchKeyInput: string;
    setSearchKeyInput: (value: string) => void;
    handleSearch: () => void;
    handleDownloadCSV : () =>void;
}

interface SearchOption {
    id: number;
    name: string;
}


const SearchFilters: React.FC<SearchFiltersProps> = ({
    selectedPool,
    handlePoolChange,
    searchOptions,
    searchTerm,
    setSearchTerm,
    searchMachineOptions,
    searchMachineTerm,
    setSearchMachineTerm,
    searchGroupTerm,
    setSearchGroupTerm,
    searchKeyInput,
    setSearchKeyInput,
    handleSearch,
    handleDownloadCSV
}) => {

    const searchGroupOptions = [
        { id: 'customerId', name: 'Customer ID' },
        // { id: 'user', name: 'User' },
        { id: 'machineModelName', name: 'Machine Model' },
        { id: 'currency', name: 'Currency' },
        // { id: 'expenseInDollar', name: 'Expense in Dollar' },
        { id: 'uptimeInHours', name: 'Uptime in Hours' },
        // { id: 'power', name: 'Power' },
        { id: 'hostingRate', name: 'Hosting Rate' },
        // { id: 'spend', name: 'Spend' },
        { id: 'workerEngine', name: 'Worker Engine' },
        { id: 'expenseDate', name: 'Expense Date' },
        // { id: 'totalCharge', name: 'Total Charge' },
    ];

    return (
        <Grid
            container
            spacing={1}
            sx={{
                marginBottom: '10px',
                alignItems: 'center',
                flexWrap: 'nowrap',
                width: '100%',
            }}
        >
            <Grid
                item
                xs={10}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                <Select
                    size="small"
                    value={selectedPool}
                    onChange={handlePoolChange}
                    sx={{ flex: 1 }}
                >
                    <MenuItem value="ALL">ALL</MenuItem>
                    <MenuItem value="SEGPOOL">SEGPOOL</MenuItem>
                    <MenuItem value="CLOUD">CLOUD</MenuItem>
                </Select>

                <Autocomplete
                    multiple
                    options={searchOptions}
                    value={searchOptions.filter((option) => searchTerm.includes(option.id))}
                    onChange={(event, newValue) => {
                        setSearchTerm(newValue.map((option) => option.id));
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Customer" size="small" fullWidth />}
                    sx={{ flex: 3 }}
                />

                <Autocomplete
                    multiple
                    options={searchMachineOptions}
                    value={searchMachineOptions.filter((option) => searchMachineTerm.includes(option.id))}
                    onChange={(event, newValue) => {
                        setSearchMachineTerm(newValue.map((option) => option.id));
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Machine Model" size="small" fullWidth />}
                    sx={{ flex: 3 }}
                />

                <Autocomplete
                    multiple
                    options={searchGroupOptions}
                    value={searchGroupOptions.filter((option) => searchGroupTerm.includes(option.id))}
                    onChange={(event, newValue) => {
                        setSearchGroupTerm(newValue.map((option) => option.id));
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Grouped By" size="small" fullWidth />}
                    sx={{ flex: 3 }}
                />

                
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    size="small"
                    label="Search Term"
                    fullWidth
                    value={searchKeyInput}
                    onChange={(e) => setSearchKeyInput(e.target.value)}
                    sx={{ width: '100%' }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />
            </Grid>
            <Grid
                item
                xs={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                <Button variant="contained" size="small" sx={{ paddingY: 1 }} onClick={handleSearch}>
                    <Search />
                </Button>
                <Button variant="contained" size="small" sx={{ paddingY: 1 }} onClick={handleDownloadCSV}>
                    <Download />
                </Button>
            </Grid>
        </Grid>
    );
};

export default SearchFilters;
